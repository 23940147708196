import React from 'react';
import { string, bool } from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
var TaglessAdUnitContent = function TaglessAdUnitContent(_ref) {
  var data = _ref.data,
    mobile = _ref.mobile,
    title = _ref.title;
  return mobile ? React.createElement("div", {
    "aria-label": "Advertisement",
    landmark: "region",
    title: title,
    dangerouslySetInnerHTML: {
      __html: data
    }
  }) : React.createElement("iframe", {
    key: _uniqueId('tagless-ads-'),
    title: title,
    src: "data:text/html,".concat(encodeURIComponent(data)),
    scrolling: "no",
    marginWidth: "0",
    marginHeight: "0",
    frameBorder: "0",
    landmark: "region",
    "aria-label": "Advertisement"
  });
};
process.env.NODE_ENV !== "production" ? TaglessAdUnitContent.propTypes = {
  data: string.isRequired,
  mobile: bool,
  title: string
} : void 0;
TaglessAdUnitContent.defaultProps = {
  mobile: true,
  title: ''
};
export default TaglessAdUnitContent;