import _extends from "@babel/runtime/helpers/esm/extends";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState, useEffect } from 'react';
import { int as _int, string, shape } from 'prop-types';
import ActionDetail from '@magalu/stereo-ui/organisms/ActionDetail';
import ReviewStats from '@magalu/stereo-ui/organisms/ReviewStats';
import ReviewResult from '../ReviewResult';
import { routePush } from '@magalu/mixer-utils';
import { usePublisher } from '@magalu/mixer-publisher';
import { Content } from './TabRating.styles';
var TabRating = function TabRating(_ref) {
  var staticProps = _ref["static"],
    data = _ref.data;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var label = staticProps.label,
    ratingTitle = staticProps.ratingTitle,
    createReviewUrl = staticProps.createReviewUrl,
    actionLabel = staticProps.actionLabel;
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe,
    publish = _usePublisher.publish;
  var review = data.review;
  var stats = review.stats;
  var handleRatingClickEvent = function handleRatingClickEvent() {
    return setIsOpen(!isOpen);
  };
  useEffect(function () {
    subscribe('product:rating:click', handleRatingClickEvent);
    return function () {
      unsubscribe('product:rating:click', handleRatingClickEvent);
    };
  });
  var onReviewButtonClick = function onReviewButtonClick() {
    publish('product:review:click');
    routePush({
      path: createReviewUrl,
      spa: true
    });
  };
  var getReviewStats = function getReviewStats() {
    return React.createElement(Content, {
      pt: [2, 2, 2, 2],
      pl: [4, 4, 4, 4],
      pr: [4, 4, 4, 4]
    }, React.createElement(ReviewStats, _extends({}, stats, {
      actionLabel: actionLabel,
      distribution: stats ? _toConsumableArray(stats.distribution) : [],
      onReviewButtonClick: onReviewButtonClick
    })));
  };
  return React.createElement(ActionDetail, {
    label: label,
    detailTitle: ratingTitle,
    open: isOpen,
    onCloseSidebar: function onCloseSidebar() {
      return setIsOpen(false);
    },
    content: getReviewStats()
  }, React.createElement(React.Fragment, null, React.createElement(ReviewResult, {
    data: data,
    "static": staticProps
  })));
};
TabRating.defaultProps = {
  data: {},
  "static": {
    label: '',
    ratingTitle: '',
    reviewPageSize: 20
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? TabRating.propTypes = {
  data: shape({}),
  "static": shape({
    actionLabel: string,
    createReviewUrl: string,
    label: string,
    ratingTitle: string,
    reviewPageSize: _int
  }),
  structure: shape({})
} : void 0;
TabRating.dataSource = {
  product: {
    query: 'ProductQuery'
  },
  review: {
    query: 'ReviewQuery'
  }
};
TabRating.ssr = true;
export default TabRating;