import React from 'react';
import { node, string } from 'prop-types';
import VizSensor from 'react-visibility-sensor';
import { usePublisher } from '@magalu/mixer-publisher';
var VisibilitySection = function VisibilitySection(_ref) {
  var children = _ref.children,
    eventKey = _ref.eventKey,
    eventValue = _ref.eventValue;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  return React.createElement(VizSensor, {
    scrollCheck: true,
    onChange: function onChange(isVisible) {
      return publish(eventKey, {
        eventValue: eventValue,
        isVisible: isVisible
      });
    }
  }, children);
};
VisibilitySection.defaultProps = {
  eventKey: 'product:active:section',
  eventValue: ''
};
process.env.NODE_ENV !== "production" ? VisibilitySection.propTypes = {
  children: node.isRequired,
  eventKey: string,
  eventValue: string
} : void 0;
export default VisibilitySection;