import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled from 'styled-components';
import { typography } from 'styled-system';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Text from '@magalu/stereo-ui/atoms/Text';
import Box from '@magalu/stereo-ui/atoms/Box';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { getMediaQuery, getPaletteColor, themeGet } from '@magalu/stereo-ui-styles';
export var BackgroundImg = styled(Image)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 80px;\n  background-color: ", ";\n\n  ", " {\n    width: 200px;\n  }\n"])), function (props) {
  return props.bgColor;
}, getMediaQuery('medium'));
export var CloseComponent = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  justify-content: flex-end;\n"])));
export var DialogStyled = styled(Dialog)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-width: 690px;\n  padding: 0px;\n"])));
export var GridContainer = styled(Grid)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", " {\n    grid-template-columns: 1fr 2fr;\n    grid-template-rows: 1fr;\n  }\n"])), getMediaQuery('medium'));
export var ImageContainer = styled(Flex).attrs(function () {
  return {
    alignItems: 'center',
    bg: 'background.primary',
    justifyContent: 'center',
    py: 2
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", " {\n    height: 360px;\n    width: 280px;\n  }\n"])), getMediaQuery('medium'));
export var InputContainer = styled(Box).attrs(function () {
  return {
    bg: 'background.base',
    padding: 2
  };
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", " {\n    padding: ", "px;\n  }\n"])), getMediaQuery('medium'), themeGet('space.4'));
export var Subtitle = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 2,
    py: 3
  }, props);
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  line-height: ", ";\n\n  ", "\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
}, typography);
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    py: 3
  }, props);
})(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  font-weight: ", ";\n\n  color: ", ";\n\n  ", " {\n    font-size: ", "px;\n    font-weight: ", ";\n  }\n\n  ", "\n"])), themeGet('fontSizes.3'), themeGet('fontWeights.medium'), function (props) {
  return getPaletteColor('greyishBrown')(props);
}, getMediaQuery('medium'), themeGet('fontSizes.5'), themeGet('fontWeights.bold'), typography);