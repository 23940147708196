import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { string, shape } from 'prop-types';
import ActionDetail from '@magalu/stereo-ui/organisms/ActionDetail';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useProductInfo } from '../../../hooks';
import ProductDetail from '../ProductDetail';
function TabProductDetail(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isOpen = _useState2[0],
    setIsOpen = _useState2[1];
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product),
    description = _useProductInfo.description;
  var label = staticProps.label,
    detailTitle = staticProps.detailTitle;
  var handleDetailClickEvent = function handleDetailClickEvent() {
    return setIsOpen(!isOpen);
  };
  return React.createElement(ActionDetail, {
    label: label,
    content: description,
    detailTitle: detailTitle,
    open: isOpen,
    onCloseSidebar: function onCloseSidebar() {
      return setIsOpen(false);
    }
  }, React.createElement(ProductDetail, {
    data: data,
    "static": _extends({}, staticProps, {
      label: '',
      py: 2
    }),
    onClick: handleDetailClickEvent
  }));
}
TabProductDetail.defaultProps = {
  data: {},
  "static": {
    detailTitle: '',
    label: ''
  }
};
process.env.NODE_ENV !== "production" ? TabProductDetail.propTypes = {
  data: shape({
    product: shape({
      description: string,
      title: string
    })
  }),
  "static": shape({
    detailTitle: string,
    label: string
  })
} : void 0;
TabProductDetail.dataSource = {
  product: {
    fragments: ['includeDescription'],
    query: 'ProductQuery'
  }
};
TabProductDetail.ssr = true;
export default withLayoutProps(TabProductDetail);