import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label"];
import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import ActionDetail from '@magalu/stereo-ui/organisms/ActionDetail';
import ProductBundleSelector from '../ProductBundleSelector';
import { useProductInfo } from '../../../hooks';
import Factsheet from '../Factsheet';
var TabFactsheet = function TabFactsheet(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"],
    structure = _ref.structure;
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product),
    factsheet = _useProductInfo.factsheet;
  var label = staticProps.label,
    props = _objectWithoutProperties(staticProps, _excluded);
  var factData = factsheet == null ? void 0 : factsheet.find(function (i) {
    return i.slug === 'ficha-tecnica';
  });
  return !!factData && React.createElement(ActionDetail, {
    label: label,
    detailTitle: label
  }, React.createElement(ProductBundleSelector, {
    layout: {
      p: 3
    },
    data: data
  }), React.createElement(Factsheet, {
    data: data,
    "static": props,
    structure: structure
  }));
};
TabFactsheet.defaultProps = {
  data: {
    product: {}
  },
  "static": {
    brandPageUrl: '',
    fontSize: 0,
    label: '',
    layout: 'vertical'
  },
  structure: {}
};
process.env.NODE_ENV !== "production" ? TabFactsheet.propTypes = {
  data: shape({
    product: shape({
      factsheet: arrayOf(shape({}))
    })
  }),
  "static": shape({
    brandPageUrl: string,
    label: string
  }),
  structure: shape({})
} : void 0;
TabFactsheet.dataSource = {
  product: {
    fragments: ['includeFactSheet']
  }
};
TabFactsheet.ssr = true;
export default TabFactsheet;