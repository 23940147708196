import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, string, shape, bool } from 'prop-types';
import ActionDetail from '@magalu/stereo-ui/organisms/ActionDetail';
import { usePublisher } from '@magalu/mixer-publisher';
import BuyBox from '../BuyBox';
var TabBuyBox = function TabBuyBox(_ref) {
  var staticProps = _ref["static"],
    structure = _ref.structure,
    data = _ref.data,
    configProp = _ref.config;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    open = _useState2[0],
    setOpen = _useState2[1];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var product = data.product;
  var label = staticProps.label,
    sidebarTitle = staticProps.sidebarTitle;
  var handleChangeOpen = function handleChangeOpen(isOpened) {
    setOpen(isOpened);
    if (isOpened) {
      publish('buybox:seemoreoffers', {
        product: product
      });
    }
  };
  return React.createElement(ActionDetail, {
    label: label,
    detailTitle: sidebarTitle,
    onChangeOpen: handleChangeOpen,
    fullScreen: true
  }, React.createElement(React.Fragment, null, open && React.createElement(BuyBox, {
    "static": staticProps,
    structure: structure,
    data: data,
    config: configProp
  })));
};
TabBuyBox.defaultProps = {
  config: {},
  data: {
    product: {}
  },
  "static": {
    itemsModal: {
      href: '/lojista/',
      showDialog: false,
      spa: true
    },
    label: '',
    sellerPagePath: '/lojista/',
    sellerPageSpa: false,
    sidebarTitle: '',
    title: 'Informações da loja parceira'
  },
  structure: {
    config: {},
    cookies: {}
  }
};
process.env.NODE_ENV !== "production" ? TabBuyBox.propTypes = {
  config: shape({
    publicRuntimeConfig: shape({
      client: shape({})
    })
  }),
  data: shape({
    product: shape({
      factsheet: arrayOf(shape({}))
    })
  }),
  "static": shape({
    itemsModal: shape({
      href: string,
      showDialog: false,
      spa: true
    }),
    label: string,
    sellerPagePath: string,
    sellerPageSpa: bool,
    sidebarTitle: string,
    title: string
  }),
  structure: shape({
    config: shape({}),
    cookies: shape({})
  })
} : void 0;
TabBuyBox.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
TabBuyBox.ssr = true;
export default TabBuyBox;