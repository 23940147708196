import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import Sidebar from '@magalu/stereo-ui/organisms/Sidebar';
import PaymentMethods from '../PaymentMethods';
var TabPaymentMethods = function TabPaymentMethods(_ref) {
  var data = _ref.data;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    _ref2$paymentMethods = _ref2.paymentMethods,
    paymentMethods = _ref2$paymentMethods === void 0 ? [] : _ref2$paymentMethods;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    visible = _useState2[0],
    setVisible = _useState2[1];
  var handlePaymentMethodClick = function handlePaymentMethodClick() {
    return setVisible(true);
  };
  var handleCloseSidebar = function handleCloseSidebar() {
    return setVisible(false);
  };
  return !!paymentMethods.length && React.createElement(Sidebar, {
    visible: visible,
    direction: "right",
    onClickOutside: handleCloseSidebar
  }, React.createElement(Sidebar.Header, {
    icon: "close",
    textAlign: "left",
    onClick: handleCloseSidebar
  }), React.createElement(PaymentMethods, {
    data: data,
    onEvent: handlePaymentMethodClick
  }));
};
process.env.NODE_ENV !== "production" ? TabPaymentMethods.propTypes = {
  data: shape({
    product: shape({
      installment: shape({}),
      paymentMethods: arrayOf(shape({}))
    })
  })
} : void 0;
TabPaymentMethods.defaultProps = {
  data: {}
};
TabPaymentMethods.ssr = true;
TabPaymentMethods.dataSource = {
  product: {
    fragments: ['showPayment'],
    query: 'ProductQuery'
  }
};
export default TabPaymentMethods;