import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape } from 'prop-types';
import StereoTextList from '@magalu/stereo-ui/organisms/TextList';
import withLayoutProps from '../../../hocs/withLayoutProps';
var TextList = function TextList(_ref) {
  var staticProps = _ref["static"];
  var hideListPoint = staticProps.hideListPoint,
    layout = staticProps.layout,
    list = staticProps.list,
    title = staticProps.title,
    titleAs = staticProps.titleAs,
    typeList = staticProps.typeList;
  return React.createElement(StereoTextList, _extends({
    hideListPoint: hideListPoint,
    list: list,
    title: title,
    titleAs: titleAs,
    typeList: typeList
  }, layout));
};
process.env.NODE_ENV !== "production" ? TextList.propTypes = {
  "static": shape({})
} : void 0;
TextList.defaultProps = {
  "static": {}
};
TextList.ssr = true;
export default withLayoutProps(TextList);