import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import styled from 'styled-components';
import { typography } from 'styled-system';
import Text from '@magalu/stereo-ui/atoms/Text';
import { themeGet } from '@magalu/stereo-ui-styles';
import PopperComponent from '@magalu/stereo-ui/atoms/Popper';
import Box from '@magalu/stereo-ui/atoms/Box';
import Grid from '@magalu/stereo-ui/atoms/Grid';
export var ClosePopper = styled(Box).attrs(function (props) {
  return _extends({
    width: 'fit-content'
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  align-self: baseline;\n  justify-self: end;\n"])));
export var Container = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n\n  /* TODO: move to another global handler\n     - control show ludetail\n  */\n\n  & ~ footer {\n    display: block;\n  }\n\n  & ~ #ludetail {\n    display: block;\n  }\n"])));
export var Content = styled(Text).attrs(function (props) {
  return _extends({
    color: 'white',
    fontSize: 1,
    lineHeight: 'display'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), typography);
export var Popper = styled(PopperComponent).attrs(function (props) {
  return _extends({
    backgroundColor: 'background.primary',
    ml: 2,
    mr: 3
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  z-index: ", ";\n"])), function (props) {
  return themeGet('zIndices.1')(props);
});
export var SuccessContainer = styled(Grid)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-template-columns: repeat(3, auto);\n  gap: 2px;\n"])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    color: 'white',
    fontSize: 2,
    fontWeight: 'bold',
    lineHeight: 'display',
    mb: 2
  }, props);
})(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n"])), typography);
export var ZipCodeBtn = styled(Text).attrs(function (props) {
  return _extends({
    color: 'white',
    fontSize: 2,
    fontWeight: 'bold',
    justifySelf: 'flex-end',
    mb: 2,
    mt: 3
  }, props);
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  cursor: pointer;\n"])));